import React, { Fragment, useEffect, useState } from 'react';
import PageHeaderTitle from '../commonComponents/PageHeaderTitle';
import { Button } from 'react-bootstrap';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { logInfo } from '../../services/logger';
import { addOrChangeUserCourse, getAllUserCourses, getUserUnselectedCourses } from '../../services/courses';
import Select from "react-select";
import makeAnimated from "react-select/animated";
import "./style.css"
import { toastError, toastSuccess } from '../../utils';

const Courses = () => {
    const Prefilledinput = makeAnimated();

    const navigate = useNavigate();
    const [allUserCourses, setAllUserCourses] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [availableCourses, setAvailableCourses] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState({
        label: "All",
        value: "All"
    });

    useEffect(() => {
        fetchData();
        fetchUserUnselectedCourses();
    }, []);

    const handleCourseLanguageSwitch = (allCourses, language) => {
        let filteredCourses;
        let langVal = language.value;
        if (langVal === 'All') {
            filteredCourses = allCourses;
        } else {
            filteredCourses = allCourses.filter((course) => course.langname === langVal);
        }
        splitCoursesIntoRows(filteredCourses);
    };


    // Update filteredCourses when language is changed
    useEffect(() => {
        handleCourseLanguageSwitch(availableCourses, selectedLanguage);
    }, [selectedLanguage]);

    // Languages array calculated from original courses
    const languages = ['All', ...new Set(availableCourses.map((course) => course.langname))];

    const fetchData = async () => {
        try {
            let { data: { data: res } } = await getAllUserCourses();
            setAllUserCourses(res);
        } catch (error) {
            console.log(error);
        }
    }
    const fetchUserUnselectedCourses = async () => {
        try {
            let { data: { data: res } } = await getUserUnselectedCourses();
            setAvailableCourses(res);
        } catch (error) {
            console.log(error);
        }
    }

    const splitCoursesIntoRows = (courses, rowSize = 4) => {
        const rows = [];
        for (let i = 0; i < courses.length; i += rowSize) {
            rows.push(courses.slice(i, i + rowSize));
        }
        return rows;
    };


    // Inside your component, after filtering courses by language:
    const filteredCoursesList = selectedLanguage?.value === 'All'
        ? availableCourses
        : availableCourses.filter((course) => course.langname === selectedLanguage?.value);

    // Split the courses into rows, each row having a maximum of two courses (or adjust as needed).
    const courseRows = splitCoursesIntoRows(filteredCoursesList);

    const handleSubmit = async () => {

        if (!selectedCourse) {
            toastError("Please select a course");
        }
        let postData = {
            courseid: selectedCourse,
        }
        try {
            let { data } = await addOrChangeUserCourse(postData);
            navigate({
                pathname: "/home",
            });
            toastSuccess("Course Updated successfully!");
        } catch (error) {
            console.log("error", error);
        }
    }

    return (
        <Fragment>
            <PageHeaderTitle title="Home" subtitle="Dashboard" />
            {allUserCourses.length > 0 && (
                <>
                    <label>My Courses</label>
                    <div className="grid">
                        {allUserCourses.map((course) => (
                            <div onClick={() => {
                                setSelectedCourse(course.sno)
                            }}>
                                <label className="card">
                                    <input name="plan" className="radio" type="radio" checked />
                                    <span className="plan-details">
                                        {course.courseiconimg && (
                                            <div>
                                                <img
                                                    className="image-layout"
                                                    src={`${course.courseiconimg}`}
                                                    // src="https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350"
                                                    alt={course.coursedisplayname}
                                                />
                                            </div>
                                        )}
                                        <span className="plan-type">{course.coursedisplayname}</span>
                                    </span>
                                </label>
                            </div>
                        ))}
                    </div>
                </>
            )}

            <Select
                classNamePrefix="Select1 mt-15"
                closeMenuOnSelect={true}
                components={Prefilledinput}
                options={languages.map((lang) => ({ label: lang, value: lang }))}
                placeholder="Select Language"
                value={selectedLanguage}
                disabled={availableCourses.length === 0}
                onChange={(value) => {
                    setSelectedLanguage(value)
                }}
            />

            <label className="mt-15">Courses Available</label>

            <div key={"a"}>
                <div >
                    {courseRows.map((row, rowIndex) => (
                        <div className="grid" key={rowIndex}
                        >
                            {row.map((course) => (
                                <div
                                    key={course.sno}
                                    onClick={() => {
                                        setSelectedCourse(course.sno)
                                    }}
                                    className="image-parent-layout"
                                >
                                    <label className="card">
                                        <input name="plan" className="radio" type="radio" checked />
                                        <span className="plan-details">
                                            {course.courseiconimg && (
                                                <div>
                                                    <img
                                                        className="image-layout"
                                                        src={`${course.courseiconimg}`}
                                                        // src="https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350"
                                                        alt={course.coursedisplayname}
                                                    />
                                                </div>
                                            )}
                                            <span className="plan-type">{course.coursedisplayname}</span>
                                        </span>
                                    </label>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
            <Button

                className=" btn-icon-text my-2 me-2 btn btn-secondary"
                onClick={() => {
                    handleSubmit()
                }}
                type="button"
            >
                Submit <i className="fa fa-angle-right ms-2"></i>
            </Button>
        </Fragment>
    );
};

export default Courses;
