import axiosInstance from "../axios"

export const getAllUserCourses = async () => {
    return await axiosInstance.get('/allusercourses');
}

export const getUserUnselectedCourses = async () => {
    return await axiosInstance.get('/userunselectedcourses');
}

export const getUserCourseDetails = async () => {
    return await axiosInstance.get('/useractivecoursecnt');
}

export const addOrChangeUserCourse = async (postData) => {
    return await axiosInstance.post('/addorchangeCourse', postData);
}